$primarycolor: #486455;
$secondarycolor : #e4af47;

.add-pet {
    background-color: $primarycolor;
    border: none;
    font-size: 1rem;
    color: $secondarycolor;
    padding: .3cm 1cm;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 1cm;
}

.overlay-add-pet {
    position: fixed;
    inset: 0;
    // backdrop-filter: brightness(30%);
    backdrop-filter: blur(5px) brightness(30%);
    padding: 1cm 5vw;
    display: flex;
    justify-content: center;
    display: none;
    z-index: 100;

}

.overlay-add-pet-box {
    width: 15cm;
    display: flex;
    flex-direction: column;
    gap: .5cm;
    background-color: #ceffe5;
    // background-color: #b2ffd6;
    height: fit-content;
    padding: .5cm;
    margin-top: 2cm;
    border-radius: 5px;
    transition: .5s;
    height: fit-content;
    overflow-y: auto;
}


.add-pet-head {
    display: flex;
    justify-content: space-between;
    // width: 100%;
    align-items: center;
    // border: 1px solid black;

}

.closeP {
    color: red;
    font-size: .8rem;
    cursor: pointer;
}

.add-pet-form {
    display: flex;
    // align-items: center;
    flex-direction: column;
    gap: .3cm;
}

.add-pet-head-text {
    font-size: 1.3rem;
}

.add-pet-input {
    padding: .3cm;
    color: $primarycolor;
    font-size: 1rem;
    background-color: transparent;
    border-radius: 5px;
    border: 1px solid $primarycolor;
}

.add-pet-submit {
    padding: .3cm;
    border: 1px solid $primarycolor;
    border-radius: 5px;
    background-color: $primarycolor;
    color: $secondarycolor;
    font-size: 1rem;
    cursor: pointer;
}

.pet-profiles {
    display: flex;
    flex-direction: column;
    gap: 1cm;
}

.pet-p {
    border: none;
}

.profile-box-dets {
    border: 1px solid rgb(221, 221, 221);
    padding: 0cm .25cm;
    border-radius: 5px;
}


@media only screen and (max-width : 800px) {
    .overlay-add-pet-box {
        width: calc(100%);
        border: 1px solid black;
    }
}