$primarycolor: #486455;
$secondarycolor : #e4af47;


.Preserve {
    padding: 0cm 0vw;
}

.prehead{
    font-weight: 600;
    font-size: 16px;
    text-align: left;
    color: $primarycolor;

}

.pretext{
    color: $primarycolor;
    text-align: left;
}

ul{
    list-style: none;
}