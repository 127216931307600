@import url('https://fonts.googleapis.com/css2?family=Quattrocento:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=:wght@400;700&display=swap');

$headFont : Quattrocento;

$primarycolor: #486455;
$secondarycolor : #e4af47;

* {
    text-decoration: none;
    padding: none;
    margin: 0;
}

.recycle-lower {
    padding: 1cm 5vw 2cm 5vw;
}

.recycle-intro {
    display: flex;
    flex-direction: column;
    gap: .2cm;
}

.rec-head {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    flex-shrink: 0;
    // text-align: left;
    // border: 1px solid black;
    color: $primarycolor;
}

.rec-text {
    text-align: center;
    font-size: 16px;
    color: $primarycolor;

}

.rec-process-text {
    // border: 1px solid black;
    height: 100%;
}



.rct {
    text-align: center;
}

.recycle-head {
    font-size: 3rem;
    font-family: $headFont;
    color: $primarycolor;
    font-weight: 600;
}

.prc {
    width: 15cm;
    border-radius: 10px;
    margin-bottom: 1cm;
}

.recycle-content {
    display: flex;
    flex-direction: column;
    gap: 3cm;
    margin-top: .5cm;
}

.rec-img-box {
    width: 4cm;
    height: auto;
    flex-shrink: 0;
}

.rec-new-box {
    width: 10cm;
    display: flex;
    flex-direction: column;
    height: fit-content;

    justify-content: space-between;
    gap: 1cm;
    align-items: center;
    border: 2.5px solid $primarycolor;
    border-radius: 10px;
    padding: .3cm;
    // height: 100%;

}


.rec-imgs {
    display: flex;
    align-items: stretch;
    gap: 1cm;
    margin-top: 1cm;
    flex-wrap: wrap;
    justify-content: center;
}

.rec-img {
    width: 4cm;
    // border-radius: 50%;
    // border: 1px solid black;
    border-radius: 10px;

}

.recycle-process {
    display: flex;
    flex-direction: column;
    gap: 1.5cm;
    margin-top: 1.5cm;
}

.rec-left {
    display: flex;
    align-items: flex-start;
    gap: 1cm;

}

.rr {
    flex-direction: row-reverse;
}

.rhh {
    text-align: center;
}

.rec-det-box {
    display: flex;
    flex-direction: column;
    gap: .3cm;
    margin-top: .5cm;
}

.rec-det {
    display: flex;
    flex-direction: row;
    gap: .2cm;
    align-items: flex-start;
}

.rlt {
    text-align: left;
}


@media only screen and (max-width : 1500px) {
    .recycle-head {
        font-size: 40px;
    }
}

@media only screen and (max-width : 1000px) {
    .rec-new-box {
        width: 8.5cm;
    }

    .rec-img {
        width: 3cm;
    }
}

@media only screen and (max-width : 900px) {
    .rec-new-box {
        width: 8cm;
    }

    .rec-img {
        width: 2.6cm;
    }

    .recycle-head {
        font-size: 36px;
    }

    .prc {
        width: 13cm;
    }
}


@media only screen and (max-width : 800px) {
    .rec-new-box {
        width: 7cm;
    }

    .rec-img {
        width: 2cm;
    }

    .rec-text {
        font-size: 14px;
    }

    .rec-head {
        font-size: 16px;
    }
}

@media only screen and (max-width : 700px) {
    .rec-imgs {
        gap: .5cm;
    }

    .rec-new-box {
        width: 100%;
        gap: .5cm;
    }

    .prc {
        width: 12cm;
    }

    .recycle-head {
        font-size: 32px;
    }
}

@media only screen and (max-width : 600px) {
    .prc {
        width: 100%;
    }

    .rec-det {
        flex-direction: column;
    }

    .rec-img {
        width: 1.5cm;
    }

    .recycle-content {
        gap: 2cm;

    }

    .recycle-lower {
        padding: 0cm 5vw;
    }
}

@media only screen and (max-width : 500px) {
    .recycle-head {
        font-size: 28px;
    }
}