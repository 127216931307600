@import url("https://fonts.googleapis.com/css2?family=Quattrocento:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
// @import url('https://fonts.googleapis.com/css2?family=:wght@400;700&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Charm:wght@400;700&family=Eagle+Lake&family=Fleur+De+Leah&family=Homemade+Apple&family=Italianno&family=Poppins&family=Italianno&family=Ms+Madi&family=Ms+Madi&family=Oleo+Script+Swash+Caps:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100..900;1,100..900&family=Ribeye+Marrow&family=The+Girl+Next+Door&display=swap");

$headFont: Quattrocento;
$primarycolor: #486455;
$secondarycolor: #e4af47;

* {
  text-decoration: none;
  padding: none;
  margin: 0;
}

::-webkit-scrollbar {
  width: 12px;
  height: 0px;
}

::-webkit-scrollbar-thumb {
  background-color: grey;
  cursor: pointer;
  // border-radius: 5px;
}

::-webkit-scrollbar-track {
  background-color: white;
}

.Home {
  position: relative;
  overflow: hidden;
}

.loader-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 105vh;
  background-color: #486455;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
  overflow: hidden;
  z-index: 999;
}

.heart {
  max-width: 5cm;
  position: relative;
  animation: fillHeart 4s ease-in-out infinite;
  border: #e4af47;
}

@keyframes fillHeart {
  0% {
    opacity: 0.3;
    transform: scale(0.8);
  }

  50% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0.3;
    transform: scale(0.8);
  }
}

.HomeHead1 {
  font-family: "Poppins";
  font-size: 30px;
  letter-spacing: 0.05cm;
  color: rgb(238, 238, 238);
  font-weight: 600;
}

.SliderCusHome {
  aspect-ratio: 16/7;
  display: flex !important;
  align-items: center !important;
  justify-content: flex-start !important;
  box-sizing: border-box;
  margin: 0;
  background-color: $primarycolor;
}

.slider-maincus {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex !important;
  align-items: center;
  position: relative;
}

.slider-maincus-flex {
  top: 0;
  left: 0;
  // width: 100%;
  // height: 100%;
  display: flex;
  flex-direction: row;
  background-image: url("./bed.jpg");
  align-items: center;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}
@keyframes imgSliderZoom {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.02);
  }
}

.slider-main-imgcus {
  width: 50%;
  right: 0;
  position: relative; /* Add this if not already present */
  background-size: cover;
  border: 10px solid rgba(14, 0, 0, 0.605);
  overflow: hidden;
  animation: imgSliderZoom 7s ease-in-out;
  animation-fill-mode: forwards; /* Ensures the final state persists */
  transition: transform 0.8s ease-in-out;
  transform-origin: center; /* Ensures proper zoom behavior */
}

// .slider-maincus::before {
//   content: "";
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;

//   background-size: cover;
//   background-position: center;
//   filter: blur(22px); /* Increase blur effect */
//   z-index: -1;
// }

// .slider-maincus::after {
//   content: "";
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background-color: rgba(0, 0, 0, 0.5); /* Adjust darkness */
//   z-index: -1;
// }

.slider-maincus-flex-col1 {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  gap: 1cm;
  background-color: rgba(0, 0, 0, 0.804); /* Adjust darkness */
  height: 100vh;
}

.main-slider-captioncus1 {
  position: relative;
  color: $secondarycolor;
  // border: 1px solid white;

  margin: 0cm 2vw;
  font-size: 3.5rem;
  line-height: 1.5cm;
  //   font-family: Homemade Apple;
}

.main-slider-captioncus2 {
  position: relative;
  color: $secondarycolor;
  // border: 1px solid white;

  margin: 0cm 2vw;
  font-size: 5rem;
  line-height: 1.8cm;
  font-family: Oleo Script Swash Caps;
}

.main-slider-buttoncus {
  padding: 0.4cm 1.2cm;
  border-radius: 50px;
  border: none;
  background-color: $secondarycolor;
  color: $primarycolor;
  font-size: 24px;
  font-weight: 650;
  cursor: url("./pawb.cur") 12 12 auto;
  cursor: pointer;
  // z-index: 2;

  &:hover {
    transform: scale(1.02);
    transition: 0.2s ease-in-out;
    font-weight: bolder;
    background-color: #f0b43d;
  }
}

.Hero {
  padding: 0cm 0cm 3cm 0cm;
  opacity: 0;
}

.home-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5cm;
  padding: 3cm 5vw 0cm 5vw;
}

.home-main-div {
  display: flex;
  gap: 2cm;
}

.hbone {
  // transform: scaleX(-1);
  // border: 10px solid $primarycolor;
  // box-sizing: border-box;
  // border-radius: 10px;
}

.htwoow {
  background-color: $primarycolor;
  width: 100vw;
}

.htwoowContent {
  display: flex;
  flex-direction: row;
  align-items: start;
  gap: 5cm;
  padding: 2cm 5vw 0cm 5vw;
}

.home-main-div-left {
  width: 12cm;
  flex-shrink: 0;
  // border-radius: 10px;
  // position: relative;

  // &::before {
  //     content: "";
  //     top: 45%;
  //     left: 55%;
  //     bottom: 0;
  //     right: 0;
  //     width: 7cm;
  //     // height: auto;
  //     // aspect-ratio: 9/16;
  //     position: absolute;
  //     background-image: url("./home-one-sub.png");
  //     background-position: center;
  //     background-repeat: no-repeat;
  //     border: 2px solid black;
  //     background-size: 7cm auto;
  //     z-index: 100;
  // }
}

.hmltt {
  // position: relative;
  // height: auto;

  // &::before {
  //     content: "";
  //     top: 40%;

  //     bottom: 0;
  //     left: -20%;
  //     width: 7cm;
  //     height: auto;
  //     // aspect-ratio: 9/16;
  //     border: 2px solid black;
  //     position: absolute;
  //     background-image: url("./home-two-sub.jpg");
  //     background-position: center;
  //     background-repeat: no-repeat;
  //     background-size: 7cm auto;
  //     // z-index: 10;
  // }
}

.home-main-div-right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.3cm;
  // border: 1px solid black;
  width: auto;
  flex-grow: 0;
}

.main-div-back {
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: #405f4e94 0px 2px 4px 0px, #2a543e79 0px 2px 16px 0px;
}

.home-main-head {
  font-family: $headFont;
  font-size: 4rem;
  color: $primarycolor;
  // font-weight: 500;
  font-weight: 700;
}

.whiteContent {
  color: white !important;
}

.main-text {
  font-size: 18px;
  text-align: left;
  font-family: Poppins;
}

.read-more {
  padding: 0.35cm 0.7cm;
  font-size: 17px;
  margin-top: 0.3cm;
  background-color: $primarycolor;
  border: 2px solid $primarycolor;
  transition: 0.3s;
  cursor: pointer;
  border-radius: 10px;
  font-weight: bolder;
  color: $secondarycolor;

  &:hover {
    border: 2px solid $secondarycolor;
    background-color: $secondarycolor;
    color: $primarycolor;
  }
}

.yellowBorder {
  border: 2px solid $secondarycolor;
}

.htwoImage {
  transform: scaleX(1.05);
  border-radius: 45% 45% 2% 2%;
  box-shadow: rgba(255, 255, 255, 0.125) 0px 15px 25px,
    rgba(255, 255, 255, 0.141) 0px 5px 10px;
}

.home-features {
  // border: 1px solid black;
  margin-top: 8cm;
  background-color: $primarycolor;
  display: flex;
  gap: 1cm;
  padding: 0.5cm 5vw;

  justify-content: space-between;
}

.fhead-main-new {
  font-size: 2.5rem;
  width: 100%;
  // border: 1px solid black;
  font-style: $headFont;
}

.home-features-right {
  width: 15cm;
  flex-shrink: 0;
  height: 100%;
}

.home-features-left-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  //    padding: .5cm 0cm;
  // justify-content: space-evenly;
  gap: 1cm;
  // border: 1px solid red;
  width: 100%;
}

.home-features-left {
  display: flex;
  // flex-direction: column;
  // flex-wrap: wrap;
  // padding: 1cm;
  gap: 5cm;
  // border: 1px solid black;
  width: 100%;
  height: 100%;
  // margin-top: 1cm;
  // justify-content: space-around;
  justify-content: center;
  align-items: center;
  // align-items: center;
}

.home-features-left-one,
.home-features-left-two {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1cm;
  justify-content: space-around;
  align-items: center;
}

.feature-back-img {
  width: 100%;
}

.feature {
  // border: 1px solid black;
  // width: 5cm;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 0.2cm;
  align-items: center;
  padding: 0cm 0.3cm;
}

.fimg {
  width: 2cm;
}

.fname {
  font-family: $headFont;
  font-size: 18px;
  margin-top: 0.2cm;
  color: $secondarycolor;
}

.ftext {
  font-size: 16px;
  line-height: 0.58cm;
  color: rgba(255, 255, 255, 0.678);
}

.collection-box {
  margin-top: 5cm;
  padding: 0cm 5vw;
}

.OcollectionsM {
  margin-top: 1cm;
  column-count: 2;
  justify-content: center;
  column-gap: 0.15cm;
}

.collection {
  // border: 1px solid black;
  padding: 0.3cm;
}

.fc {
  width: 10cm;
  height: 8cm;
  // background-color: rgb(231, 231, 231);
  position: relative;
  border-radius: 10px;

  &::before {
    content: "";
    position: absolute;
    background-image: url("./wear.jpg");
    inset: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    filter: brightness(70%);
    z-index: -1;
    border-radius: 10px;
  }
}

.sc {
  width: 10cm;
  height: 5cm;
  // background-color: rgb(231, 231, 231);
  margin-top: 0.15cm;
  border-radius: 10px;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    background-image: url("./play.jpg");
    inset: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    filter: brightness(80%);
    border-radius: 10px;
    z-index: -1;
  }
}

.tc {
  width: 10cm;
  height: 5cm;
  // background-color: rgb(231, 231, 231);
  position: relative;

  &::before {
    content: "";
    position: absolute;
    background-image: url("./bed.jpg");
    inset: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    filter: brightness(70%);
    z-index: -1;
    border-radius: 10px;
  }
}

.foc {
  margin-top: 0.15cm;
  width: 10cm;
  height: 8cm;
  // background-color: rgb(231, 231, 231);
  position: relative;

  &::before {
    content: "";
    position: absolute;
    background-image: url("./walk.jpg");
    inset: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    filter: brightness(80%);
    z-index: -1;
    border-radius: 10px;
  }
}

.ctext {
  color: white;
  background-color: $primarycolor;
  font-size: 15px;
  width: fit-content;
  padding: 0.1cm 0.4cm;
  border-radius: 1000px;
}

.collections {
  margin-top: 1cm;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0.7cm;
}

.collection {
  padding: 1cm;
  width: 5cm;
  height: 5cm;
  border-radius: 10px;
  background-color: #e7c278;
  width: fit-content;
  aspect-ratio: 1/1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(248, 60, 92);
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  transition: 0.3s all ease;

  &:active {
    box-shadow: none;
  }

  &:hover {
    background-color: #496d52bc;
    transform: translateY(5px);

    .coltext {
      color: $secondarycolor;
    }
  }
}

.coltext {
  font-size: 55px;
  font-weight: bolder;
  letter-spacing: 0.1cm;
  font-family: Saira Stencil One;
}

.wear {
  position: relative;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background-image: url("./wear.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
    opacity: 0.25;
  }
}

.walk {
  background-color: #e7c278;
  color: rgb(134, 13, 247);
  position: relative;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background-image: url("./walk.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
    opacity: 0.25;
  }
}

.coplay {
  background-color: #e7c278;
  color: forestgreen;
  position: relative;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background-image: url("./play.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
    opacity: 0.25;
  }
}

.sleep {
  background-color: #e7c278;
  color: rgb(31, 83, 238);
  position: relative;
  z-index: 1;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    background-image: url("./bed.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: -1;
    opacity: 0.25;
  }
}

.featured-products-box {
  margin-top: 5cm;
  padding: 0cm 5vw;
  // border: 10px solid black;
  box-sizing: border-box;
  overflow-x: auto;
}

.featured-products {
  display: flex;
  flex-shrink: 0;
  overflow-x: auto;
  gap: 0.7cm;
  padding: 0.3cm;
  margin-top: 0.5cm;
  justify-content: flex-start;
}

.fproduct {
  width: 7cm;
  border: 3px solid $primarycolor;
  padding: 0.15cm;
  flex-shrink: 0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  gap: 0.3cm;
}

.prodimg {
  width: 90%;
}

.fprod-upper {
  display: flex;
  flex-direction: column;
  gap: 0.5cm;
  background-color: $primarycolor;
  padding: 0.5cm 0.5cm;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
  border: 2px solid $primarycolor;

  &:hover {
    background-color: white;

    .prod-cat {
      color: $primarycolor;
    }

    .prod-name {
      color: $primarycolor;
    }

    .prod-price {
      color: #486455;
    }
  }
}

.prod-name {
  font-size: 18px;

  font-style: $headFont;

  line-height: 0.6cm;
  color: $secondarycolor;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* Limit to 2 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.prod-cat {
  font-size: 12px;
  color: white;
  margin-bottom: 0.2cm;
  // color: rgba(255, 255, 255, 0.623);
}

.prod-desc {
  display: flex;
  flex-direction: column;
  // gap: .1cm;
}

.prod-price {
  color: $primarycolor;
  // font-weight: 600;
  color: $secondarycolor;
}

.add-cart {
  padding: 0cm 0.5cm;
  width: 100%;
  background-color: $primarycolor;
  border: 3px solid $primarycolor;
  cursor: pointer;
  font-size: 1rem;
  transition: 0.3s all ease;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  &:hover {
    background-color: transparent;
    color: $primarycolor;
  }
}

.ProductCard {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  row-gap: 0.2cm;
  border-radius: 5px;
  width: 9cm;
  // background-color: #40533e;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  // border:2px solid $primarycolor;
}

.imgCont {
  position: relative;
  overflow: hidden;
  width: 9cm;
  height: auto;
  border-radius: 5px;
}

.productImg {
  width: 100%;
  height: 100%;
  transition: transform 0.3s ease-in-out;
  object-fit: cover;
}

.ProductCard:hover .productImg {
  transform: scale(1.08);
}

.overlayProdCard {
  position: absolute;
  top: 0;
  left: 0;
  width: 1.5cm;
  height: 100%;
  background: rgba(255, 255, 255, 0);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 8px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: 10;
  padding: 0.2cm 0.15cm;
}

.imgCont:hover .overlayProdCard {
  opacity: 1;
}

.smallBtn {
  width: 0.7cm;
  background-color: rgb(255, 255, 255);
  border-radius: 30%;
  padding: 4px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.productCardRowCus {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;
}

.productName {
  font-size: 18px;
  color: #293c2e;
  font-weight: 600;
  /* text-align: center; */
  margin-top: 8px;
}
.productCategory {
  font-size: 12px;
  color: #496d52;
  font-weight: 300;
}

.productPrice {
  font-size: 16px;
  color: #35473d;
  font-weight: 550;
  /* text-align: center; */
}

.smallImgCartCont {
  position: relative;
}

.smallImgCartCont::after {
  position: absolute;
  content: "Add to Cart";
  color: rgb(223, 185, 80);
  background-color: #40533e;
  top: 0.12cm;
  left: 1.53cm;
  width: 2.5cm;
  height: auto;
  padding: 0.15cm;
  font-size: 12px;
  font-weight: bolder;
  opacity: 0;
  transition: ease-in-out 0.45s;
  border-radius: 01px 5px 5px 0;
}

.smallImgCartCont::before {
  position: absolute;
  content: "";
  top: 0.095cm;
  left: 1.28cm;
  width: 0cm;
  height: 0cm;
  font-size: 13px;
  border-top: 16px solid transparent;
  border-bottom: 16px solid transparent;
  border-right: 10px solid #425240;
  opacity: 0;
  transition: ease-in-out 0.45s;
}

.smallImgCartCont:hover::after,
.smallImgCartCont:hover::before {
  opacity: 1;
}

.smallImgHeartCont {
  position: relative;
}

.smallImgHeartCont::after {
  position: absolute;
  content: "Add to Wishlist";
  color: rgb(223, 185, 80);
  background-color: #40533e;
  top: 0.055cm;
  left: 1.53cm;
  width: 2.5cm;
  height: auto;
  padding: 0.18cm;
  font-size: 12px;
  font-weight: bolder;
  opacity: 0;
  transition: ease-in-out 0.45s;
  border-radius: 01px 5px 5px 0;
}

.smallImgHeartCont::before {
  position: absolute;
  content: "";
  top: 0.055cm;
  left: 1.28cm;
  width: 0cm;
  height: 0cm;
  font-size: 13px;
  border-top: 16px solid transparent;
  border-bottom: 16px solid transparent;
  border-right: 10px solid #40533e;
  opacity: 0;
  transition: ease-in-out 0.45s;

}

.smallImgHeartCont:hover::after,
.smallImgHeartCont:hover::before {
  opacity: 1;
}

.noProductImg {
  width: 3cm;
}

@media screen and (max-width: 1024px) and (min-width: 850px) {
  .categoryBtn {
    font-size: 14px;
  }

  .ProductCardRow {
    gap: 0.6cm;
  }

  .ProductCard {
    max-width: 8cm;
  }

  .imgCont {
    align-items: center;
    width: 8cm;
  }
}

@media screen and (max-width: 850px) and (min-width: 750px) {
  .categoryBtn {
    font-size: 13px;
  }

  .ProductCardRow {
    gap: 0.5cm;
  }

  .ProductCard {
    max-width: 7cm;
  }

  .imgCont {
    align-items: center;
    width: 7cm;
  }
  .productName {
    font-size: 16px;
  }
  .productPrice {
    font-size: 14px;
  }
}

@media screen and (max-width: 750px) and (min-width: 650px) {
  .categoryBtn {
    font-size: 13px;
    padding: 0.18cm 0.8cm;
  }

  .ProductCardRow {
    gap: 0.5cm;
  }

  .ProductCard {
    max-width: 6cm;
  }

  .imgCont {
    align-items: center;
    width: 6cm;
  }
}

@media screen and (max-width: 650px) and (min-width: 550px) {
  .categoryBtn {
    font-size: 12px;
    padding: 0.12cm 0.68cm;
  }

  .ProductCardRow {
    gap: 0.4cm;
  }

  .ProductCard {
    max-width: 6cm;
  }

  .imgCont {
    align-items: center;
    width: 6cm;
  }
}

@media screen and (max-width: 550px) {
  .categoryBtn {
    font-size: 12px;
    padding: 0.1cm 0.6cm;
  }

  .ProductCardRow {
    gap: 0.2cm;
  }

  .ProductCard {
    max-width: 6.5cm;
  }

  .imgCont {
    align-items: center;
    width: 6.5cm;
  }
}

@media screen and (max-width: 768px) {
  .overlayProdCard {
    opacity: 1;
  }
}

.our-testimonials-box {
  margin-top: 3.5cm;
  width: 100%;
}

.our-testimonials-component {
  display: flex;
  flex-direction: column;
  // row-gap: 0.2cm;
}

.testimonials-row1 {
  width: 100%;
  margin-bottom: 1cm;
}

.testimonials-heading {
  font-size: 22px;
  font-weight: 600;
  text-align: center;
}

.testimonials-row2 {
  background-color: $primarycolor;
  padding: 0.5cm;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.testimonials-row3{
  border-top: 5px solid $secondarycolor;
  background-color: $primarycolor;
  padding: 0.3cm;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.testimonials-row3-head{
  font-size: 30px;
  color: $secondarycolor;
  font-weight: 700;
}

.testimonials-row3-row2{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.8cm;
  margin-top:0.5cm;
  padding-bottom:0.5cm;
}
.testimonials-row1-col1
 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.8cm;
}

// .SliderParent {
//   width: 100%;
//   max-width: 80%;
//   margin: auto;
//   background-color: transparent;
// }

.testimonials-row1-col2 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  // width: 100%;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.8cm;
}


.testimonials-smallLogo {
  width: 3.5cm;
  padding: 1cm;
  border-radius: 30%;
  cursor: pointer;
}

.reviewBox {
  width:10cm;
  position: relative;
  word-wrap: break-word;
  text-align: justify;
  padding: 0.2cm;
  border-radius: 5px;
  font-size: 16px;
  color: $primarycolor;
  background-color: $secondarycolor;
  border: 2px solid $secondarycolor;
  box-shadow: rgba(89, 89, 89, 0.3) 0px 19px 38px, rgba(68, 68, 68, 0.22) 0px 15px 12px;

    &::before{
      position: absolute;
      content: " ";
      top: 3cm;
      left: -0.25cm;
      width: 0cm;
      height: 0cm;
      font-size: 15px;
      border-top: 16px solid transparent;
      border-bottom: 16px solid transparent;
      border-right: 10px solid $secondarycolor;
      transition: ease-in-out 0.45s;
      z-index: 1000;
    }
}

.testimonials-ClientLogo{
  width:4cm;
  height:4cm;
  // height: auto;
  border-radius: 50%;

  padding: 0.2cm;
   &:hover{
    transform:scale(1.2);
    transition: 0.15s ease-in-out;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px; 

  }
}

.testimonials-head{
  display: flex;
  justify-content: flex-start;
}
.borderImg {
  border: 2px solid $secondarycolor;
  border-radius: 50%;
}
.satisfiedCustomersNumber {
  font-size: 30px;
  font-weight: bold;
  color: $secondarycolor;
}



.carousel {
  width: 100%;
  height: auto;
  aspect-ratio: 16/9;
}

.fhmm {
  color: white;
}

.transformations {
  margin-top: 5cm;
  padding: 0cm 0vw;
  display: flex;
  height: fit-content;
  flex-direction: column;
  align-items: center;
}

.transformations-slider {
  display: flex;
  justify-content: flex-start;
  width: 20cm;
  overflow: hidden;
  height: auto;
  padding: 0.5cm;
  margin-top: 1cm;
  // border: 1px solid rgb(221, 221, 221);
}

.slide {
  // width: 20cm;
  // border: 1px solid black;
  display: flex;
  justify-content: center;
  gap: 0.5cm;
  align-items: center;
  // height: auto;
  // opacity: 0;
  // transition: opacity 0.7s ease;
}

.slide-left {
  // border: 1px solid black;
  width: 50%;
  // aspect-ratio: 9/16;
  height: fit-content;

  position: relative;
}

.slide-right {
  // border: 1px solid black;
  width: 50%;
  // aspect-ratio: 9/16;

  height: fit-content;
  position: relative;
}

.before {
  // border: 1px solid red;
  padding: 0.15cm 1cm;
  width: fit-content;
  font-size: 24px;
  position: absolute;
  background-color: $primarycolor;
  color: $secondarycolor;
  // border-radius: 1000px;
  // z-index: 12;
  // padding: .3cm;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.3cm;
  top: 0;
  margin-bottom: 0.2cm;
  left: 0;
}

.after {
  // border: 1px solid red;
  padding: 0.1cm 1cm;
  width: fit-content;
  font-size: 24px;
  position: absolute;
  color: $secondarycolor;
  background-color: $primarycolor;
  // border-radius: 10px;
  z-index: 12;
  // padding: .3cm;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.3cm;
  top: 0;
  margin-bottom: 0.2cm;
  left: 0;
}

.transformationLogo {
  width: 1cm;
}
.slide-left-img {
  width: 100%;
}

.transformed {
  width: 1cm;
  height: 1cm;
  cursor: pointer;
  opacity: 0.4;
  transition: 0.3s;

  &:hover {
    opacity: 1;
  }
}

.transformedR {
  transform: rotate(180deg);
}

.slick-slider-custom {
  margin-top: 0.7cm;
  // margin-bottom: .3cm;
  width: 21.5cm;
  // border: 2px solid green;
  height: auto;
  display: flex !important;
  gap: 0.5cm;
  align-items: center;
  justify-content: flex-start;
  // border: 1px solid red;
  // border: 1px solid red;
  // position: relative;
  border: 15px solid $primarycolor;
  border-radius: 5px;
}

.slick-slide-custom {
  // Optional slide styling
  background-color: $primarycolor;

  display: flex !important;
  gap: 0.5cm;
  justify-content: flex-start;
  // height: 13.1cm;
}

.slick-slide-img-custom {
  width: 10cm;
}

.home-features-new-ssd {
  display: flex !important;
  align-items: center;
  justify-content: flex-start;
  gap: 2cm;
  // width: 100%;
  width: 20cm !important;
  overflow: auto;
  // border: 1px solid white;
}

.slick-two-slider-new {
  // border: 1px solid red;
  width: 100%;
  display: flex !important;
}

.thh {
  position: absolute;
  z-index: 1000;
  opacity: 1;
  padding: 0.3cm 1cm;
}

.thhr {
  right: 0;
}

.fprod-lower {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row-reverse;
  height: 1.2cm;
  gap: 0.2cm;
  // border: 1px solid black;
}

.heart-box {
  width: auto;
  height: calc(100% - 0.3cm);
  aspect-ratio: 1/1;
  padding: 0.1cm;
  display: flex;
  align-items: center;
  border: 2px solid $primarycolor;
}

.heart {
  width: 100%;
  height: auto;
}

.view-button {
  padding: 0.2cm 0.5cm;
  width: 8.2cm;
  background-color: transparent;
  border: 3px solid $primarycolor;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: 0.3s all ease;
  color: $primarycolor;

  &:hover {
    background-color: $primarycolor;
    color: white;
  }
}

@media only screen and (max-width: 1200px) {
  .home-main-div {
    gap: 1cm;
  }

  .collection {
    width: 4cm;
    height: 4cm;
  }

  .fproduct {
    width: 6cm;
    gap: 0.2cm;
  }

  .fprod-upper {
    padding: 0.3cm;
  }

  .prod-name {
    font-size: 16px;
    line-height: 0.5cm;
  }
}

@media only screen and (max-width: 1100px) {
  .featured-products {
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 1000px) {
  .slick-slider-custom {
    width: 100%;
    padding: 0cm 5vw;
    // margin-top: 1.5cm;
  }

  .home-main-div-left {
    width: 10cm;
  }
}

@media only screen and (max-width: 900px) {
  .home-main-div {
    flex-direction: column;
  }

  .htwoow {
    flex-direction: column-reverse;
  }

  .home-main-head {
    font-size: 40px;
  }
}

@media only screen and (max-width: 800px) {
  .fproduct {
    width: 5.5cm;
    gap: 0.2cm;
  }

  .nproducts {
    gap: 0.5cm 0.3cm;
  }

  .fprod-upper {
    padding: 0.3cm;
  }

  .prod-name {
    // font-size: 14px;
    line-height: 0.5cm;
  }

  .add-cart {
    padding: 0.15cm;
    font-size: 14px;
  }
}

@media only screen and (max-width: 700px) {
  .main-slider-captioncus {
    font-size: 70px;
  }

  .collection {
    width: 4cm;
    height: 4cm;
  }

  .home-main-head {
    font-size: 32px;
  }

  .before,
  .after {
    // padding: .2cm;
    font-size: 16px;
    padding: 0.2cm 0.5cm;
  }
}

@media only screen and (max-width: 600px) {
  .slider-maincus {
    img {
      width: 100%;
      height: 100%;
    }
  }

  .home-main {
    padding: 1cm 5vw;
    gap: 3cm;
  }

  .collection-box {
    margin-top: 3cm;
  }

  .featured-products-box {
    margin-top: 3cm;
  }

  .collection {
    width: 3cm;
    height: 3cm;
  }

  .main-slider-captioncus {
    font-size: 60px;
    line-height: 1.8cm;
  }

  .coltext {
    font-size: 45px;
  }
}

@media only screen and (max-width: 500px) {
  .home-main-div-left {
    width: 100%;
  }

  .collection {
    width: 3cm;
    height: 3cm;
  }

  .coltext {
    font-size: 40px;
  }

  .main-slider-captioncus {
    font-size: 50px;
    line-height: 1.5cm;
  }

  .home-main-head {
    font-size: 28px;
  }
}

@media only screen and (max-width: 450px) {
  .collection {
    width: 2cm;
    height: 2cm;
  }

  .coltext {
    font-size: 32px;
  }

  .nproducts {
    gap: 1cm;
  }

  .fproduct {
    width: 100%;
  }

  .prod-name {
    font-size: 16px;
  }

  .add-cart {
    font-size: 16px;
    padding: 0.3cm;
  }
}

@media only screen and (max-width: 400px) {
  .collection {
    width: 1.3cm;
    height: 1.3cm;
  }

  .coltext {
    font-size: 30px;
  }
}
